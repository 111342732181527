<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ cashRegister.name }}
                <base-button
                  v-if="
                    $currentUserCan(
                      $permissions.PERM_EDIT_CASH_REGISTER_REGISTERS
                    )
                  "
                  type="primary"
                  icon
                  size="sm"
                  @click="editCashRegister()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan(
                      $permissions.PERM_DELETE_CASH_REGISTER_REGISTERS
                    )
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteCashRegister()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      printModalOpened = true;
                    }
                  "
                  v-if="
                    $currentUserCan($permissions.PERM_CREATE_CASH_REGISTERS)
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="btn-inner--text">
                    {{ $t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING") }}
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <cash-register-view-global
                    :cashRegister="cashRegister"
                    @cashRegisterUpdated="get"
                  />
                </tab-pane>
                <tab-pane title="cashRegisterReadings" id="2">
                  <span slot="title">
                    <i class="fa fa-calculator" />
                    {{ $t("COMMON.CASH_REGISTER_READINGS") }}
                  </span>
                  <cash-register-view-reading
                    v-if="cashRegister.id"
                    :filterOrganization="cashRegister.organization?.id"
                    :cashRegister="cashRegister.id"
                    @cashRegisterUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <cash-register-view-logs :cashRegister="cashRegister" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
    <modal
      :show.sync="printModalOpened"
      modal-classes="modal-secondary"
      size="xl"
    >
      <div>
        <h2>
          {{ $t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING") }}
        </h2>
        <div>
          <base-input
            :label="`${$t('CASH_REGISTER_READINGS.STARTS_AT')} (*)`"
            :placeholder="$t('CASH_REGISTER_READINGS.STARTS_AT')"
          >
            <flat-picker
              :config="{
                allowInput: true,
                allowInput: true,
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: 'today',
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="cashRegisterReading.starts_at"
              @on-change="
                () => {
                  onFormChanged();
                }
              "
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.starts_at" />
          <base-input
            :label="`${$t('CASH_REGISTER_READINGS.ENDS_AT')} (*)`"
            :placeholder="$t('CASH_REGISTER_READINGS.ENDS_AT')"
          >
            <flat-picker
              :config="{
                allowInput: true,
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: 'today',
                locale: $flatPickrLocale(),
              }"
              class="form-control datepicker"
              v-model="cashRegisterReading.ends_at"
              @on-change="
                () => {
                  onFormChanged();
                }
              "
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.ends_at" />

          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              @click="handleSubmitcashRegisterReading()"
            >
              {{ $t("COMMON.SAVE") }}
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import defaultCashRegister from "./defaultCashRegister";
import defaultCashRegisterReading from "./defaultCashRegisterReading";
import CashRegisterViewGlobal from "./partials/CashRegisterViewGlobal.vue";
import CashRegisterViewLogs from "./partials/CashRegisterViewLogs.vue";
import CashRegisterViewWarehouseProducts from "./partials/CashRegisterViewWarehouseProducts.vue";
import CashRegisterViewReading from "./partials/CashRegisterViewReading.vue";
import flatPicker from "vue-flatpickr-component";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CashRegisterViewGlobal,
    CashRegisterViewWarehouseProducts,
    CashRegisterViewLogs,
    CashRegisterViewReading,
    flatPicker,
    OrganizationSelector,
    BaseInput,
    ValidationError,
  },

  mixins: [formMixin],

  data() {
    const currentDateTime = this.getCurrentDateTime();
    console.log(currentDateTime);
    const cashRegisterReading = cloneDeep(defaultCashRegisterReading);
    return {
      cashRegisterReading: {
        ...cashRegisterReading,
        starts_at: currentDateTime,
        ends_at: currentDateTime,
      },
      printModalOpened: false,
      cashRegister: cloneDeep(defaultCashRegister),
    };
  },

  computed: {},

  created() {
    this.get();
  },

  methods: {
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} 00:00`;
    },
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("cashRegisters/get", id);

        const cashRegister = this.$store.getters["cashRegisters/cashRegister"];
        this.cashRegister = cashRegister;

        this.cashRegisterReading = {
          ...this.cashRegisterReading,
          serviceCenter:
            cashRegister.serviceCenter ||
            this.cashRegisterReading.serviceCenter,
          cafeteria:
            cashRegister.cafeteria || this.cashRegisterReading.cafeteria,
          cashRegister: {
            type: "cash-registers",
            id: cashRegister.id,
          },
          establishment:
            cashRegister.establishment ||
            this.cashRegisterReading.establishment,
          organization:
            cashRegister.organization || this.cashRegisterReading.organization,
        };
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async handleSubmitcashRegisterReading() {
      this.loading = true;
      const cashRegisterReadingData = this.cashRegisterReading;
      if (cashRegisterReadingData.serviceCenter) {
        if (!cashRegisterReadingData.serviceCenter.id) {
          delete cashRegisterReadingData.serviceCenter;
        }
      }
      if (cashRegisterReadingData.establishment) {
        if (!cashRegisterReadingData.establishment.id) {
          delete cashRegisterReadingData.establishment;
        }
      }
      if (cashRegisterReadingData.cafeteria) {
        if (!cashRegisterReadingData.cafeteria.id) {
          delete cashRegisterReadingData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch(
          "cashRegisterReadings/add",
          cashRegisterReadingData
        );

        this.$notify({
          type: "success",
          message: this.$t(
            "CASH_REGISTER_READINGS.CASH_REGISTER_READING_ADDED"
          ),
        });
        const cashRegisterReading = await this.$store.getters[
          "cashRegisterReadings/cashRegisterReading"
        ];
        this.viewCashRegisterReading(cashRegisterReading);
        this.loading = false;
        this.printModalOpened = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const cashRegisterReading = await this.$store.getters[
          "cashRegisterReadings/cashRegisterReading"
        ];
        if (cashRegisterReading.id) {
          await this.$store.dispatch(
            "cashRegisterReadings/destroy",
            cashRegister.id
          );
        }
        this.loading = false;
      }
    },
    async editCashRegister(row) {
      this.$router.push({
        name: "Edit CashRegister",
        params: { id: this.cashRegister.id },
      });
    },

    async deleteCashRegister() {
      const confirmation = await swal.fire({
        title: this.$t("CASH_REGISTERS.DELETE_THIS_CASH_REGISTER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "cashRegisters/destroy",
            this.cashRegister.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("CASH_REGISTERS.CASH_REGISTER_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    viewCashRegisterReading(cashRegisterReading) {
      this.$router.push({
        name: "View CashRegisterReading",
        params: { id: cashRegisterReading.id },
      });
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
    goBack() {
      this.$router.push({ name: "List CashRegisters" });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
